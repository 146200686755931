<template>
    <div class="modal-faceRating">
        <div class="header">
            <span class="title">외모 평가 후 주선해주세요!</span>
            <span class="desc">외모 평가가 안 된 경우, 자동 주선 기능을 사용할 수 없어요.</span>
        </div>
        <div class="body">
            <div class="info-photo-wrapper">
                <span class="info">
                    <span class="name f-medium f-16">{{ options.user.name }} </span>
                    ({{ options.user.profile.birthday | asAge }}세, {{ options.user.profile.height }}cm)
                </span>
                <div class="user-photo-wrapper">
                    <img
                        v-for="photo in options.user.photos || []"
                        class="photo"
                        :key="photo.id"
                        :src="considerBlankProfile(photo)"
                        @error="$event.target.src = $blankProfile"
                    />
                </div>
            </div>
            <div class="button-wrapper">
                <div class="worst-best flex-row flex-between">
                    <div class="worst" v-html="'<아쉬움'" />
                    <div class="best" v-html="'호감>'" />
                </div>
                <div class="m-b-8 flex-row">
                    <button v-for="idx in 5" class="rateable" :key="`rateable-button${idx}`" @click="evaluate(idx)">
                        {{ idx }}
                    </button>
                </div>
                <button class="unrateable" @click="evaluate(-2)">판단 불가 / 인물사진 아님</button>
                <span class="desc text-center">외모 판단 가능한 사진이 없거나, 인물(본인) 사진이 아닌 경우</span>
            </div>
            <!--            <div class="modal-faceRating-body-sample">-->
            <!--                <span><span class="def-text-title">예시 사진 </span>(외모 평가 기준을 참고해주세요!)</span>-->
            <!--                <div class="m-t-16">-->
            <!--                    <div-->
            <!--                        v-for="(ratingSamples, idx) in photos"-->
            <!--                        :key="`rating${idx + 1}_samples`"-->
            <!--                        class="sample-ratings"-->
            <!--                    >-->
            <!--                        <div class="numbering">{{ 5 - idx }}</div>-->
            <!--                        <div class="photo-wrapper">-->
            <!--                            <img v-for="idx in 5" :key="idx" :src="(ratingSamples[idx] || {}).pathLong" />-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'ModalFaceRating',
    props: {
        options: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        // photos: [[], [], [], [], []],
    }),
    mounted() {
        // this.initSamplePhotos()
    },
    methods: {
        // initSamplePhotos() {
        //     // template literals inside require.context() provoke typeError
        //     if (this.options.user.gender === 0) {
        //         const dataMale = require.context('@/assets/images/appearance_rate_sample/male/', false, /\.png$/)
        //         dataMale.keys().forEach(key => {
        //             // key: ex) ./01_117451.png
        //             const rating = key.charAt(3)
        //             this.photos[`${rating - 1}`].push({ pathLong: dataMale(key) })
        //         })
        //     } else {
        //         const dataFemale = require.context('@/assets/images/appearance_rate_sample/female/', false, /\.png$/)
        //         dataFemale.keys().forEach(key => {
        //             const rating = key.charAt(3)
        //             this.photos[`${rating - 1}`].push({ pathLong: dataFemale(key) })
        //         })
        //     }
        //
        //     this.photos = this.photos.reverse()
        // },
        async evaluate(rating) {
            try {
                const { msg } = await userService.updateAppearanceRating(this.options.user.id, 1, rating)
                this.$toast.success(msg)
            } catch (e) {
                this.$toast.error(e.data.msg)
            } finally {
                this.$emit('close')
            }
        },
        considerBlankProfile(item) {
            return item.url ? item.url : this.$blankProfile
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-faceRating {
    $user-photo-size: 160px;
    $sample-photo-size: 88px;
    $padding-vertical: 24px;
    $padding-horizontal: 20px;
    $gap: 8px;
    $button-wrapper-height: 138px;

    width: 536px;
    height: 653px;
    padding: 0;
    @include flex;

    .header {
        padding: $padding-vertical $padding-horizontal;
        border-bottom: solid 1px $grey-02;
        line-height: normal;
        @include flex;
    }

    .title {
        font-size: 18px;
        color: black;
        margin-bottom: $gap;
        @include f-bold;
    }

    .desc {
        color: $grey-08;
    }

    .body {
        padding: $padding-vertical $padding-horizontal;
        flex-grow: 1;
        @include flex;
        @include flex-between;

        .info {
            line-height: normal;
            color: black;
            font-size: 13px;
            @include f-regular;
        }

        //&-sample {
        //    flex-grow: 1;
        //    background-color: $grey-02;
        //    border-bottom-right-radius: 12px;
        //    padding: $padding-vertical $padding-horizontal;
        //
        //    @include flex;
        //}

        .user-photo-wrapper {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            row-gap: $gap;
            column-gap: $gap;
            margin-top: 16px;
            margin-bottom: $padding-vertical;
        }

        .photo {
            width: $user-photo-size;
            height: $user-photo-size;
            border-radius: 8px;
            border: solid 1px $grey-02;
        }

        .button-wrapper {
            margin-bottom: $gap;
            @include flex;

            .worst-best {
                font-size: 11px;
                color: $purple-primary;
                @include f-bold;
                margin-bottom: 4px;
            }

            button {
                height: 36px;
                border: solid 1px white;
                color: white;
                font-size: 14px;
                font-weight: 500;
                @include f-medium;

                &:first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }

                &:last-child {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }

            .desc {
                font-size: 12px;
                line-height: 1.5;
                color: $pink-deep;
                @include f-medium;
            }
        }

        .rateable {
            width: auto;
            background-color: $purple-primary;
            border-radius: 2px;
        }

        .unrateable {
            width: 100%;
            border-radius: 8px;
            background-color: $pink-deep;
            margin-bottom: 4px;
        }

        //.sample-ratings {
        //    margin-bottom: 40px;
        //    @include flex-row;
        //
        //    &:last-child {
        //        margin-bottom: 30px;
        //    }
        //
        //    .numbering {
        //        display: flex;
        //        justify-content: center;
        //        align-items: center;
        //        width: 36px;
        //        height: 36px;
        //        border-radius: 8px;
        //        border: solid 1px #8a74ff;
        //        font-family: NotoSans-Medium;
        //        color: #8a74ff;
        //        margin-right: 12px;
        //    }
        //
        //    .photo-wrapper {
        //        display: grid;
        //        grid-template-columns: repeat(5, 1fr);
        //        column-gap: 7px;
        //
        //        img {
        //            width: 88px;
        //            height: 88px;
        //            border-radius: 8px;
        //            border: solid 1px #f1f2f4;
        //        }
        //    }
        //}
    }

    @media (max-width: $screen-xs) {
        .title {
            font-size: 24px;
        }

        .desc {
            font-size: 13px;
        }

        .body {
            position: relative;

            .info-photo-wrapper {
                padding-bottom: calc(#{$button-wrapper-height} - #{$padding-vertical});
            }

            .user-photo-wrapper {
                grid-template-columns: repeat(2, 1fr);
            }
            .photo {
                $screen-xs-photo-size: calc((100vw - 2 * #{$padding-horizontal} - 2 * #{$gap}) / 2);
                width: $screen-xs-photo-size;
                height: $screen-xs-photo-size;
            }

            .button-wrapper {
                border: 1px solid $grey-02;
                position: fixed;
                left: 0;
                bottom: 0;
                padding: 16px 20px;
                width: 100%;
                background: white;
                margin-bottom: 0;
            }
        }
    }
}
</style>
